<template>
  <div class="content margin-preview">
    <v-row class="flex-column" no-gutters>
      <v-col cols="12" lg="7">
        <p
					class="vue-editor text-md-h1 text-h5 font-weight-black"
					v-html="trans('help_page_title')"
				/>
      </v-col>
    </v-row>
		<v-row no-gutters>
      <v-col cols="12" md="6" lg="5">
        <p
					class="vue-editor mt-4 mt-md-9 text-md-subtitle-1 text-body-2 grayText--text"
					v-html="trans('help_page_subtitle')"
				/>
				<div class="d-flex align-center mt-md-9 mt-6 mb-6 mr-3">
					<v-sheet width="20" height="20" class="mr-2">
						<v-img
							contain
							src="@/assets/images/help-page-phone-icon.svg"
						/>
					</v-sheet>
					<span class="text-body-2 text-md-body-1 font-weight-bold mr-1">
						<span v-html="trans('help_page_support_line_name')" class="vue-editor" />
						<span> : </span>
					</span>
					<span
						class="vue-editor text-body-2 text-md-body-1"
						v-html="trans('help_page_support_line_value')"
					/>
				</div>
				<div class="d-flex align-center">
					<v-sheet width="20" height="17" class="mr-2">
						<v-img
							contain
							src="@/assets/images/help-page-email-icon.svg"
						/>
					</v-sheet>
					<span class="text-body-2 text-md-body-1 font-weight-bold mr-1">
						<span v-html="trans('help_page_support_email_name')" class="vue-editor" />
						<span> : </span>
					</span>
					<a
						:href="'mailto:' + trans('help_page_support_email_value')"
						class="vue-editor text-decoration-none black--text mail-link text-body-2 text-md-body-1"
						v-html="trans('help_page_support_email_value')"
					/>
				</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-md-15 mb-6">
      <v-col
        cols="12"
        md="5"
        class="search">
        <v-text-field
          v-model="searchQuery"
          single-line
          hide-details
          :placeholder="trans('help_page_search')"
          outlined
          append-icon="mdi-magnify">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-expansion-panels class="faq-section accordion" accordion>
        <v-expansion-panel
          active-class="expanded"
          v-for="item in faqList"
          :key="item.order">
          <v-expansion-panel-header
						expand-icon="mdi-menu-down"
						class="vue-editor header text-body-1"
						v-html="item.title"
					/>
          <v-expansion-panel-content
					>
						<div
							v-html="item.content"
							class="vue-editor text-md-body-1 grayText--text pb-8 text-caption"
						/>
					</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.content {
  margin-bottom: 150px;
}
.search::v-deep() {
  margin-top: 78px;

  .v-input {
    border-radius: 10px;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.accordion {
  border-radius: 10px !important;
  border: 1px solid var(--v-borderGray-base) !important;
}

.header {
  color: var(--v-grayText-base);
}

.expanded::v-deep() {
  .header {
    color: var(--v-black-base);
    font-weight: bold;

    .v-expansion-panel-header__icon .v-icon {
      color: var(--v-mainColor-base) !important;
    }
  }
}
.faq-section {
  scroll-margin-top: 50px;
}

.mail-link {
	transition: .2s;
	&:hover {
		color: var(--v-mainColor-base) !important;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content {
    margin-bottom: 60px;
  }
  .content::v-deep() {
    .search {
      margin-top: 40px;

      fieldset {
        height: 50px;
      }

      .v-text-field__slot {
        font-size: 14px;
        top: -5px;
      }

      .v-input__append-inner {
        margin-top: 12px;
      }
    }

}

  .header {
    min-height: 75px !important;
  }

}
</style>

<script>
import ApiFaq from '@/api/faq'
export default {
  name: 'Help',
  data: () => ({
    searchQuery: ''
  }),
  created() {
    ApiFaq.getAll(this)
  },
  computed: {
    faqList () {
      return this.state.faqList.filter(item => item.title.toLowerCase().includes(this.searchQuery.toLowerCase()))
    }
  }
}
</script>
