import ApiBase from '@/api/base'

class ApiFaq {
	static getAll (self) {
		self.http('get', `${ApiBase.baseApiUrl()}/faq`)
			.then(response => {
				ApiBase.setToStore(null, 'setFaqList', response.data)
			})
	}
}

export default ApiFaq
